import { getApolloClient } from '@faustwp/core/dist/mjs/client';
import { __ } from '@wordpress/i18n';
import { GetStaticProps } from 'next';
import dynamic from 'next/dynamic';
import { Main } from '@/components/elements/Main/Main';
import { Header } from '@/components/layouts/Header/Header';
import { Layout } from '@/components/layouts/Layout/Layout';
import { getFragmentData } from '@graphqlTypes/fragment-masking';
import {
	FooterSettingsFragFragmentDoc,
	PrimaryMenuItemsFragFragmentDoc,
	SiteIdentityFragFragmentDoc,
	Get404PageQuery,
	SeoFragFragment,
	MediaItemFragFragmentDoc,
	MenuItemNodeFragFragment,
	Get404PageDocument,
} from '@graphqlTypes/graphql';

const Footer = dynamic( () =>
	import( '../components/layouts/Footer/Footer' ).then( ( mod ) => mod.Footer )
);

interface Page404Props {
	data: Get404PageQuery;
	loading: boolean;
}

const Page404 = ( { data, loading }: Page404Props ) => {
	const identityFrag = getFragmentData( SiteIdentityFragFragmentDoc, data?.siteConfig );
	const logo = getFragmentData(
		MediaItemFragFragmentDoc,
		identityFrag?.identity?.axewpLogoLight?.node
	);

	const footerFrag = getFragmentData( FooterSettingsFragFragmentDoc, data?.siteConfig );
	const footer = footerFrag?.footerSettings?.axewpFooterContent;

	const primaryMenuFrag = getFragmentData( PrimaryMenuItemsFragFragmentDoc, data );
	const menu = primaryMenuFrag?.primaryMenuItems?.nodes;

	const seo = {
		title: __( '404 - Not Found', 'axewp' ),
		description: __( '404 - Not Found', 'axewp' ),
	} as SeoFragFragment;

	return (
		<Layout loading={ loading } seo={ seo }>
			<Header logo={ logo } menu={ menu as MenuItemNodeFragFragment[] } />
			<Main className="wp-block-group flex min-h-[480px] flex-col justify-center">
				<h1 className="wp-block-heading has-x-large-font-size text-center font-extrabold tracking-tight">
					{ __( '404 - Not Found', 'axewp' ) }
				</h1>
			</Main>
			<Footer content={ footer } />
		</Layout>
	);
};

export const getStaticProps: GetStaticProps< Page404Props > = async () => {
	const client = getApolloClient();

	const { data, loading } = await client.query< Get404PageQuery >( {
		query: Get404PageDocument,
	} );

	return {
		props: {
			data: data || {},
			loading,
		},
	};
};

export default Page404;
